$fa-font-path: "../fonts";
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/light";
@import "@fortawesome/fontawesome-pro/scss/brands";/**
 * Handles all font loading separate from main theme so that fonts are not
 * render-blocking. This file is loaded separately from theme.css!
 *
 * NOTE: This file must exist and the font families chosen in themeconfig.json
 * will be loaded automatically by the build process.
 *
 * Place @font-face directives at the top of this file
 */

 @font-face {font-family: "Helvetica W02 Bold";
   src: url("../fonts/static/helvetica-bold.eot"); /* IE9*/
   src: url("../fonts/static/helvetica-bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
   url("../fonts/static/helvetica-bold.woff2") format("woff2"), /* chrome、firefox */
   url("../fonts/static/helvetica-bold.woff") format("woff"), /* chrome、firefox */
   url("../fonts/static/helvetica-bold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
   url("../fonts/static/helvetica-bold.svg#Helvetica W02 Bold") format("svg"); /* iOS 4.1- */
 }

// Default font style, mostly used for icons baked into the templates
.fa {
	// Font Awesome defaults to fa = fas
	//@extend .far;
}

.fa, .fas, .far, .fal, .fab {

	// While fonts are loading
	.wf-loading & {
		// Prevent FOUT
		color: transparent;
	}
}
